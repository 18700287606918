import React, { Component } from 'react';
import { Input, Label, Select, Textarea } from '../../../shared/form';
import api from '../../../api';
import { toast } from 'react-toastify';
import { formatFullDate, allFieldsHaveValues_new, reloadPage } from '../../../util';
//import MapObject from '@components/map';
import Loading from 'shared/loading';
import { MILISECONDS_BEFORE_CLOSE } from '../../../config';
import Editor from './editor';

const getDataFromAPIResponse = (initialData, attributes) => {
	const dataForReturn = {};

	for (let key in initialData) {
		if (attributes.includes(key)) {
			dataForReturn[key] =
				typeof initialData[key] == 'object' || typeof initialData[key] == 'boolean'
					? initialData[key]
					: initialData[key].toString();
		}
	}

	return dataForReturn;
};

// marked with '_edit' at the end will be fillable from 'deliveryForm' modal
const textVariables = {
	PRICE_INFO_FOR_USERS: [], // ['{TEXT1}','{TEXT2}']
	EMAIL_PDF_LADING: [
		'{TITLE}',
		'{LIG_NR}_edit',
		'{TODAY}',
		'{TODAY_FULL}',
		'{LOAD_DATE}',
		'{LOAD_DATE_FULL}',
		'{LATRAPS}',
		'{LOAD_ADDR}',
		'{UNLOAD_DATE}_edit',
		'{UNLOAD_DATE_FULL}',
		'{RECEIVER}',
		'{RECEIVER_PHONE}',
		'{RECEIVER_EMAIL}',
		'{UNLOAD_ADDR}',
		'{UNLOAD_ADDR_LOCATION}',
		'{MATERIAL}',
		'{UNIT}',
		'{AMOUNT}',
		'{CAR}',
		'{PAYMENT_TERM}_edit',
		'{CARRIER_PRICE}',
		'{CARRIER_PRICE_UNIT}',
		'{CARRIER}',
		'{CARRIER_LEGALADDR}',
		'{CARRIER_REGNR}',
		'{CARRIER_CONTACTNAME}',
		'{CARRIER_PHONE}',
		'{CARRIER_EMAIL}',
		'{WAREHOUSE}',
		'{USER}',
		'{USER_PHONE}',
		'{USER_EMAIL}',
		'{DRIVER_PHONE}',
		'{SPACE}',
	],
	EMAIL_PDF_LADING_SMALL: [
		'{STORAGE_ADDR}',
		'{RECEIVER}',
		'{RECEIVER_PHONE}',
		'{DELIVERY_ADDR}',
		'{CAR}',
		'{CARRIER}',
		'{DRIVER}',
		'{MATERIAL}',
		'{UNIT}',
		'{AMOUNT}',
		'{USER}',
		'{SPACE}',
	],
	PLANNING_ORDER_NOTIFICATION: [
		'{AMOUNT}',
		'{UNIT}',
		'{MATERIAL}',
		'{DELIVERY_ADDR}',
		'{DELIVERY_DATE_FROM}',
		'{DELIVERY_DATE_TO}',
	],
}

class EmailTemplateEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			toSaveData: {},
			data: {},
		};
	}

	setData = () => {
		const { id } = this.props;

		if (!id) {
			return;
		}

		this.setState({ loading: true });

		api.admin.get_specific_template(id).then(response => {
			const moreAttrs = getDataFromAPIResponse(response, [
				'name',
				'description',
				'template',
			]);

			const newState = Object.assign(
				{ loading: false, error: false, data: {...response, tmpTemplate: response.template || null} },
				moreAttrs
			);

			this.setState(newState);
		});
	};

	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.setData();
		}
	}

	setDataToState = (key, value) => {
		const toSaveData = Object.assign(this.state.toSaveData, { [key]: value });

		this.setState({ toSaveData, [key]: value });
	};

	handleChange = event => {
		const inputValue =
			event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		const name = event.target.name;
		this.setDataToState(name, inputValue);
	};

	saveData = e => {
		e.preventDefault();

		const { toSaveData, data } = this.state;
		const { id } = this.props;

		if (Object.keys(toSaveData).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			console.log('nav ko saglabāt');
			return;
		}

		let reallyChanged = {};

		for (let key in toSaveData) {
			if (toSaveData[key] !== data[key]) {
				reallyChanged[key] = toSaveData[key];
			}
		}

		if (Object.keys(reallyChanged).length === 0) {
			toast.info('Dati netika laboti', { autoClose: 3500 });
			console.log('Data was not changed');
			return;
		}

		const defaultFields = {code: this.props.category};

		let try2Final = {};
		const dataToSendAsDefaults = [
			'name',
			'description',
			'tmpTemplate',
			'templateHtml',
		];
		for (let arrKey in dataToSendAsDefaults) {
			let theKey = dataToSendAsDefaults[arrKey];
			if (theKey in reallyChanged && typeof reallyChanged[theKey] !== 'undefined') {
				try2Final[theKey] = reallyChanged[theKey];
			} else {
				try2Final[theKey] = data[theKey];
			}
		}

		const finalToSend = Object.assign(try2Final, defaultFields);

		console.log('to save', JSON.stringify(finalToSend), toSaveData);

		const nameMap = {
			name: 'Nosaukums',
			description: 'Apraksts',
			tmpTemplate: 'Veidnes teksts',
		};
		const confirmAllFieldsHasValues = allFieldsHaveValues_new(
			[
				'name',
				'description',
				'tmpTemplate',
			],
			finalToSend,
			nameMap
		);

		if (confirmAllFieldsHasValues.status == false) {
			toast.warn(`Nav aizpildīts "${confirmAllFieldsHasValues.name}" lauks`, {
				autoClose: 7500
			});
			return;
		}

		finalToSend.template = finalToSend.tmpTemplate;

		api.admin.email_template_save({id, data: finalToSend}).then(response => {
			if (response.status == 'ok') {
				toast.success('Dati saglabāti');

				// setTimeout(() => reloadPage(), MILISECONDS_BEFORE_CLOSE);
				setTimeout((e) => this.props.handleCloseModal(e, true), MILISECONDS_BEFORE_CLOSE);
			} else {
				toast.warn(
					`Ir problēmas ar datu saglabāšanu. Paziņojums: ${response.message}`,
					{ autoClose: 4000 }
				);
			}
		});
	};

	addToText = (text) => {
		this.setDataToState('template', `${this.state.template} ${text}`);
	}

	saveTemplateText = (text, html) => {
		this.setDataToState('tmpTemplate', text);
		this.setDataToState('templateHtml', html);
	}

	render() {
		const {
			loading,
			name, description, template
		} = this.state;

		const {preview} = this.props;
		const isPreview = preview ?? false;

		const variables = textVariables[this.props.category] ?? [];

		if (loading) {
			return <div className='bodyWrapper'><Loading /></div>
		}

		return (
			<div className='bodyWrapper'>
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title='Nosaukums'
						name='name'
					/>
					<Input
						inputClass='col-sm-8'
						name='name'
						value={name}
						handleChange={this.handleChange}
					/>
				</div>
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title='Apraksts'
						name='description'
					/>
					<Input
						inputClass='col-sm-8'
						name='description'
						value={description}
						handleChange={this.handleChange}
					/>
				</div>
				<div className='row'>
					<Label
						labelClass='col-sm-3'
						title='Veidnes teksts'
						name='template'
					/>
				</div>
				<div className='row'>
					<Editor
						initState={template ?? null}
						setText={(state, html) => this.saveTemplateText(state, html)}
						variables={variables}
						category={this.props.category}
					/>
				</div>
				<button
					type='button'
					disabled={isPreview}
					className='btn btn-primary'
					onClick={this.saveData}>
					Saglabāt!
				</button>
				<div style={{ clear: 'both', padding: 10 }} />
			</div>
		);
	}
}

export default EmailTemplateEdit;
